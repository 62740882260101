import { useDomainContext } from "@hooks/useDomainContext";
import { affiliateOfferZones } from "@lib/shared/config";
import { replacePlaceholders } from "@lib/sharedUtils";
import React, { ReactNode } from "react";
import OffersList from "../offersList";

interface TextBlockJSXProps {
    offerContainerStyle?: string;
    textBlockStyle?: string;
    children?: ReactNode;
}

const TextBlockOffer = ({
    offerContainerStyle,
    textBlockStyle,
    children,
}: TextBlockJSXProps) => {
    const { domain, category, visitDetails } = useDomainContext();

    const offersHeadlineTextBlock = domain.textBlocks?.find(
        (i) => i.position === "offers_headline",
    );

    return (
        <section
            className={`${
                offerContainerStyle ? offerContainerStyle : ""
            } offerListContainer`}
        >
            <div className=" py-14 ">
                {offersHeadlineTextBlock ? (
                    <div
                        className={`${
                            textBlockStyle ? textBlockStyle : ""
                        } text-center block mx-auto p-3 textBlock`}
                        dangerouslySetInnerHTML={{
                            __html: replacePlaceholders(
                                offersHeadlineTextBlock?.text,
                                {
                                    category: category?.name,
                                    region: visitDetails.region,
                                    city: visitDetails?.city,
                                },
                            ),
                        }}
                    ></div>
                ) : (
                    <></>
                )}
                <OffersList
                    offers={domain?.defaultOffers}
                    showInForm={true}
                    zone={affiliateOfferZones.SWITCH_UI_LISTING}
                />
            </div>
            {children}
        </section>
    );
};

export default TextBlockOffer;
