import React from "react";

function Pinterest() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69"
            height="35"
            fill="none"
            viewBox="0 0 69 35"
        >
            <rect width="69" height="35" fill="#e60023" rx="4"></rect>
            <mask
                id="mask0_32_1516"
                style={{ maskType: "alpha" }}
                width="19"
                height="15"
                x="25"
                y="10"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#D9D9D9"
                    d="M25.93 10H43.016999999999996V25H25.93z"
                ></path>
            </mask>
            <g mask="url(#mask0_32_1516)">
                <path
                    fill="#fff"
                    d="M30.756 18.573a.327.327 0 00.382-.078.262.262 0 00.056-.117c.037-.14.129-.486.172-.631.043-.145.037-.263-.123-.436a2.15 2.15 0 01-.573-1.541c-.007-.505.1-1.005.314-1.47.214-.466.53-.887.929-1.239a4.177 4.177 0 011.392-.806 4.492 4.492 0 011.631-.244c2.33 0 3.607 1.29 3.607 3.01 0 2.262-1.104 4.172-2.75 4.172a1.46 1.46 0 01-.613-.103 1.331 1.331 0 01-.494-.343 1.168 1.168 0 01-.27-.509 1.1 1.1 0 01.014-.564c.26-.994.765-2.066.765-2.792.02-.15.005-.3-.045-.444a1.03 1.03 0 00-.244-.388 1.162 1.162 0 00-.4-.26 1.265 1.265 0 00-.483-.087c-.924 0-1.67.866-1.67 2.028-.006.428.088.853.277 1.245l-1.116 4.273a8.05 8.05 0 000 2.982c.004.02.014.038.03.053a.12.12 0 00.055.03.13.13 0 00.064 0 .12.12 0 00.055-.032 8.308 8.308 0 001.566-2.57c.104-.351.616-2.161.616-2.161.233.312.549.566.918.738.37.171.782.256 1.197.245 2.787 0 4.673-2.301 4.673-5.384 0-2.324-2.176-4.468-5.48-4.468-4.12 0-6.166 2.675-6.166 4.903-.056 1.34.505 2.542 1.714 2.988z"
                ></path>
            </g>
        </svg>
    );
}

export default Pinterest;
