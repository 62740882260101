import { TemplateConfig, Variables } from "./ts/types";

export const breakpoints = ["sm", "md", "lg", "xl", "xxl"];

// ****** EXAMPLE INPUT ******
// variables = {
//     "titleSize": {
//         "sm": "14px",
//         "md": "16px",
//     },
//     "descriptionSize": "14px",
//     "titleColor": {
//          "sm": "red",
//          "lg": "blue",
//     },
// }

// ****** OUTPUT ******
// breakpointVariables = {
//     "--title-size-sm": "14px",
//     "--title-size-md" : "16px",
//     "--title-size-lg" : "16px",
//     "--title-size-xl" : "16px",
//     "--title-size-xxl" : "16px",
//     "--description-size-sm": "14px",
//     "--description-size-md": "14px",
//     "--description-size-lg": "14px",
//     "--description-size-xl": "14px",
//     "--description-size-xxl": "14px",
//     "--title-color-sm": "red",
//     "--title-color-md": "red",
//     "--title-color-lg": "blue",
//     "--title-color-xl": "blue",
//     "--title-color-xxl": "blue",
// }

/**
 * Convert variables object to sass variables with breakpoints handled
 *
 * @param variables - variables object to convert
 */
export function convertToBreakpointVariables(variables: Variables): {
    [key: string]: string;
} {
    const breakpointVariables: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(variables)) {
        if (!value) continue;
        if (typeof value === "object") {
            const objValue = value as { [key: string]: string | undefined };
            breakpoints.forEach((breakpoint, index) => {
                breakpointVariables[`--${key}-${breakpoint}`] =
                    objValue[breakpoint] ||
                    objValue[breakpoints[index - 1]] ||
                    objValue[breakpoints[index - 2]] ||
                    objValue[breakpoints[index - 3]] ||
                    objValue[breakpoints[index - 4]] ||
                    objValue["sm"] ||
                    "unset";
            });
        } else {
            breakpoints.forEach((breakpoint) => {
                breakpointVariables[`--${key}-${breakpoint}`] = value;
            });
        }
    }

    return breakpointVariables;
}

export function generateGlobalVariables(config: TemplateConfig) {
    const cssVariables: Record<string, string | undefined> = {};

    function recursiveGenerateCSSVariables(obj: TemplateConfig, prefix = "") {
        for (const key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];

                if (typeof value === "object" && value !== null) {
                    recursiveGenerateCSSVariables(value, `${prefix}-${key}`);
                } else {
                    const variableName = `--${prefix}-${key
                        .replace(/([a-z])([A-Z])/g, "$1-$2")
                        .toLowerCase()}`;
                    cssVariables[variableName] = value || "unset";
                }
            }
        }
    }

    recursiveGenerateCSSVariables(config, "global");

    return cssVariables;
}

export function modifyCSSForElement(
    cssString: string | undefined,
    selector: string,
) {
    if (!cssString) return "";

    // Split the CSS rules by '}' to work with individual rules
    const rules = cssString.split("}");

    // Iterate through each rule and prepend the selector
    const modifiedRules = rules.map((rule) => {
        if (rule.trim() === "") {
            return ""; // Skip empty rules
        }
        return `${selector} ${rule.trim()}`;
    });

    // Join the modified rules back together with '}' and return the result
    return modifiedRules.join("\n}\n");
}
