import { useDomainContext } from "@hooks/useDomainContext";
import { getMainTitlesNavigationFromBody } from "@lib/sharedUtils";
import OneElement from "../oneElement";
import Share from "./share/Share";
import Facebook from "../icons/Facebook";
import Twitter from "../icons/Twitter";
import Pinterest from "../icons/pinterest";
import { useRouter } from "next/router";
export default function Mobile() {
    const { asPath } = useRouter();

    const { blogPost, domain } = useDomainContext();

    const shareList = [
        {
            icon: <Facebook />,
            link: `https://www.facebook.com/sharer/sharer.php?u=https://${domain.name}${asPath}`,

            name: "facebook",
        },
        {
            icon: <Twitter />,
            link: `https://twitter.com/share?url=https://${domain.name}${asPath}`,
            name: "twitter",
        },
        {
            icon: <Pinterest />,
            link: `http://pinterest.com/pin/create/button/?url=https://${domain.name}${asPath}`,
            name: "pinterest",
        },
    ];
    const myList = [
        {
            title: "Contents",
            slug: "contents",
            content: getMainTitlesNavigationFromBody(
                blogPost?.model.body as string,
            ),
        },
    ];
    return (
        <div
            className={`   px-5 py-10  max-h-[80vh] flex flex-col justify-between `}
        >
            {myList?.map((el, index) => (
                <OneElement key={index} content={el.content} />
            ))}
            <div>
                <h3 className={`mt-4 text-sm font-bold`}>Share</h3>
                <div className={`flex  gap-2 mt-3 justify-center  `}>
                    <Share shareList={shareList} />
                </div>
            </div>
        </div>
    );
}
