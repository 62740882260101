import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

const Share = ({
    shareList,
}: {
    shareList: { icon: ReactNode; link: string; name: string }[];
}) => {
    return (
        <>
            {shareList?.map((el, index) => (
                <a
                    key={index}
                    className={`cursor-pointer  ${styles[el.name]}`}
                    onClick={() => {
                        window.open(
                            el.link,
                            "",
                            "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600",
                        );
                        return false;
                    }}
                >
                    {el.icon}
                </a>
            ))}
        </>
    );
};

export default Share;
