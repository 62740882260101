import React from "react";

function Twitter() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69"
            height="35"
            fill="none"
            viewBox="0 0 69 35"
        >
            <rect width="69" height="35" fill="#35a9ff" rx="4"></rect>
            <mask
                style={{ maskType: "alpha" }}
                width="18"
                height="15"
                x="26"
                y="10"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#D9D9D9"
                    d="M26.025 10H43.111999999999995V25H26.025z"
                ></path>
            </mask>
            <g clipPath="url(#clip0_32_1513)">
                <path
                    fill="#fff"
                    d="M26.01 22.15a10.97 10.97 0 005.135 1.374 11.06 11.06 0 005.201-1.167c1.58-.8 2.878-1.965 3.755-3.369.877-1.403 1.298-2.99 1.217-4.59a6.803 6.803 0 001.779-1.595 7.52 7.52 0 01-2.012.484c.736-.38 1.289-.984 1.553-1.697a7.756 7.756 0 01-2.22.75 3.665 3.665 0 00-1.983-.933c-.76-.111-1.54.002-2.22.32-.68.32-1.22.826-1.537 1.442a2.725 2.725 0 00-.218 1.966 11.02 11.02 0 01-3.997-.927 9.796 9.796 0 01-3.218-2.277 2.746 2.746 0 00-.38 2.234c.203.76.727 1.424 1.467 1.856a3.872 3.872 0 01-1.553-.381c-.01.715.265 1.41.777 1.966s1.228.939 2.026 1.081a4.064 4.064 0 01-1.553.055 3.1 3.1 0 001.24 1.525 3.804 3.804 0 002.022.602 7.394 7.394 0 01-2.492 1.114 7.931 7.931 0 01-2.79.168z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_32_1513">
                    <path
                        fill="#fff"
                        d="M0 0H17.048V12.164H0z"
                        transform="translate(26.025 11.364)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Twitter;
