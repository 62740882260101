import React, { ReactElement } from "react";
import { orderListByKeyword, doesOfferHaveALink } from "@lib/sharedUtils";
import { ListingItem } from "@lib/shared/types";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { getOffersByPlacement, finalShowForm } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
const Banner = dynamic(() => import("@components/shared/offer"));

export default function OffersList({
    offers,
    style,
    fullWidth = false,
    showInForm = false,
    zone,
}: {
    offers: ListingItem[] | undefined;
    style?: { [x: string]: string } | undefined;
    fullWidth?: boolean;
    showInForm?: boolean;
    zone?: string | undefined;
}): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const showListAbove = !finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    if (Array.isArray(offers) && offers.length) {
        const keyword = router.query?.k as string;
        const orderedListOffers = orderListByKeyword(
            keyword,
            offers.filter(
                (item) => item?.placements?.skipMainOffersList !== true,
            ),
        );

        if (!showInForm && showListAbove) {
            return <></>;
        }

        return getOffersByPlacement(orderedListOffers, "offersList")?.map(
            (offer, index) => (
                <Banner
                    offer={offer}
                    key={index}
                    style={style ?? undefined}
                    displayLinkAndPhoneNumber={doesOfferHaveALink(offer)}
                    fullWidth={fullWidth}
                    zone={zone}
                />
            ),
        );
    }
    return <></>;
}
