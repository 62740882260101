import styles from "./style.module.scss";
import { setModal } from "@redux/shared/actions";
import { useDispatch } from "react-redux";

export default function OneElement({
    content,
}: {
    content?: { title: string | null; link: string }[];
}) {
    const dispatch = useDispatch();

    return (
        <>
            {(content?.length as number) > 0 && (
                <div className={`${styles["linksList"]}      `}>
                    <h3 className="text-lg  mt-8 mb-5  hidden lg:block ">
                        Contents
                    </h3>
                    <div className="max-h-[300px] overflow-y-auto">
                        {content?.map((el, index) => (
                            <div
                                key={index}
                                className={`
                                ${styles["bulletedListItem"]} pl-5    
                                     pb-5  `}
                            >
                                <a
                                    href={el?.link ?? "#"}
                                    dangerouslySetInnerHTML={{
                                        __html: el?.title as string,
                                    }}
                                    onClick={() =>
                                        dispatch(
                                            setModal({
                                                active: false,
                                                content: undefined,
                                            }),
                                        )
                                    }
                                    className={`${styles["linkColor"]}   block  cursor-pointer text-sm `}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
